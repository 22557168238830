import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Swipe from 'react-easy-swipe';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Image from '../../utils/OptimizedImage';
import { getTag } from '../../utils/getTag';
import {
  HeroWrapper,
  SlideContent,
  SlideContentWrapper,
  SlideNav,
  SlideNavItem,
} from './HeroCarouselComponent.style';

const HeroCarousel = ({
  component: { headingKickerTag, headingTag, slides },
}) => {
  const didMount = useRef(false);
  const slideDuration = 6_000;
  const cooldownDuration = 1_250;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [displayedSlideIndex, setDisplayedSlideIndex] = useState(0);
  const [stopped, setStopped] = useState(false);
  // Prevent animations from being applied on the initial state
  const [isInitialState, setIsInitialState] = useState(true);
  const navCooldown = useRef(false);
  const Heading = headingTag ? getTag(headingTag) : 'h2';
  const Kicker = headingKickerTag ? getTag(headingKickerTag) : 'p';

  const goToPreviousSlide = useCallback(() => {
    if (navCooldown.current) {
      return;
    }

    setIsInitialState(false);
    setStopped(true);

    setActiveSlideIndex(prevState => {
      if (prevState === 0) {
        return slides.length - 1;
      }

      return prevState - 1;
    });
  }, [slides.length]);

  const goToNextSlide = useCallback(
    (auto = false) => {
      if (navCooldown.current) {
        return;
      }

      setIsInitialState(false);

      if (auto !== true) {
        setStopped(true);
      }

      setActiveSlideIndex(prevState => {
        if (prevState === slides.length - 1) {
          return 0;
        }

        return prevState + 1;
      });
    },
    [slides.length]
  );

  useEffect(() => {
    clearTimeout(window.heroCarouselTimeout);

    if (!stopped) {
      window.heroCarouselTimeout = setTimeout(() => {
        goToNextSlide(true);
      }, slideDuration);
    }
  }, [activeSlideIndex, goToNextSlide, stopped]);

  useEffect(() => {
    setTimeout(() => {
      setDisplayedSlideIndex(activeSlideIndex);
    }, cooldownDuration);
  }, [activeSlideIndex]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;

      return;
    }

    navCooldown.current = true;

    setTimeout(() => {
      navCooldown.current = false;
    }, cooldownDuration);
  }, [activeSlideIndex]);

  return (
    <Swipe
      allowMouseEvents
      onSwipeLeft={goToNextSlide}
      onSwipeRight={goToPreviousSlide}
    >
      <HeroWrapper>
        <div className="preload">
          {slides.map(slide => (
            <Image
              alt={slide.backgroundImage.alt}
              image={slide.backgroundImage.gatsbyImageData}
              title={slide.backgroundImage.title}
            />
          ))}
        </div>
        <AnimatePresence initial={false} mode="wait">
          {slides.map((slide, slideIndex) => {
            return (
              slideIndex === activeSlideIndex && (
                <motion.div
                  animate={{
                    opacity: 1,
                    originX: '75%',
                    originY: 'top',
                    scale: 1.075,
                    transition: {
                      duration: 1.25,
                    },
                    z: '0',
                    zIndex: 1,
                  }}
                  className={`bg-wrapper${
                    isInitialState ? ' initial-state' : ''
                  }`}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0,
                      delay: 1.25,
                    },
                    z: '0',
                    zIndex: 0,
                  }}
                  initial={{
                    opacity: 0,
                    originX: '75%',
                    originY: 'top',
                    scale: 1,
                    z: '0',
                    zIndex: 1,
                  }}
                  key={slide.heading}
                >
                  <Image
                    image={slide.backgroundImage.gatsbyImageData}
                    alt={slide.backgroundImage.alt}
                    title={slide.backgroundImage.title}
                  />
                </motion.div>
              )
            );
          })}
        </AnimatePresence>
        <Container>
          <SlideContentWrapper>
            {slides.map((slide, slideIndex) => {
              const CurrentKicker =
                slideIndex === displayedSlideIndex ? Kicker : 'div';
              const CurrentHeading =
                slideIndex === displayedSlideIndex ? Heading : 'div';

              return (
                <SlideContent
                  $isInitialState={isInitialState}
                  className={activeSlideIndex === slideIndex ? 'active' : ''}
                  key={slide.heading}
                >
                  <CurrentKicker
                    className={`heading-kicker${
                      slideIndex === activeSlideIndex &&
                      slideIndex === displayedSlideIndex
                        ? ' displayed'
                        : ''
                    }`}
                  >
                    {slide.headingKicker}
                  </CurrentKicker>
                  <CurrentHeading
                    className={`heading${
                      slideIndex === activeSlideIndex &&
                      slideIndex === displayedSlideIndex
                        ? ' displayed'
                        : ''
                    }`}
                  >
                    {slide.heading}
                  </CurrentHeading>
                  <div className="cta-wrapper">
                    <CallToAction
                      icon={slide.callToAction?.icon}
                      link={slide.callToAction?.url}
                      reverse={slide.callToAction?.reverse}
                      size={slide.callToAction?.size}
                      value={slide.callToAction?.label}
                      variant={slide.callToAction?.variant}
                    />
                  </div>
                </SlideContent>
              );
            })}
          </SlideContentWrapper>
          <SlideNav>
            {slides.map((slide, slideIndex) => {
              return (
                <SlideNavItem
                  $duration={slideDuration}
                  className={`${
                    activeSlideIndex === slideIndex ? 'active' : ''
                  } ${stopped ? 'stopped' : ''}`}
                  key={slide.heading}
                  onClick={() => {
                    if (navCooldown.current) {
                      return;
                    }

                    setStopped(true);

                    if (activeSlideIndex === slideIndex) {
                      return;
                    }

                    setIsInitialState(false);
                    setActiveSlideIndex(slideIndex);
                  }}
                  type="button"
                >
                  <div className="bar" />
                  <div className="title">{slide.heading}</div>
                </SlideNavItem>
              );
            })}
          </SlideNav>
        </Container>
      </HeroWrapper>
    </Swipe>
  );
};

export default HeroCarousel;
